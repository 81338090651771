'use strict';

function showPreLoader() {
    // if (!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    //   var element = document.getElementsByClassName('page-loader');
    //   if (element) {
    //     element.style.display = '';
    //   }
    // }
    //$('.loader').fadeIn();
    $('app-root').prepend('<div class="loader" style="display: none;"><div class="bs-loading"><div class="bs-loader">Loading...</div><p>Carregando..</p></div></div>');
    $('.loader').fadeIn();
};

function dismissPreLoader() {
    // var fadePreLoader = function (element) {
    //   var op = 1; // initial opacity
    //   var timer = setInterval(function () {
    //     if (op <= 0.1) {
    //       clearInterval(timer);
    //       element.style.display = 'none';
    //     }
    //     element.style.opacity = op;
    //     element.style.filter = 'alpha(opacity=' + op * 100 + ")";
    //     op -= op * 0.1;
    //   }, 10);
    // }

    // setTimeout(function () {
    //   fadePreLoader(document.getElementsByClassName('page-loader'));
    // }, 200);
    $('.loader').fadeOut(0);
    $('.loader').remove();
}
